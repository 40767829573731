<template>
  <v-dialog required v-model="bosExampleModal" scrollable width="600px">
    <template v-slot:activator="{ on }">
      <div v-on="on" style="cursor: pointer" class="ml-2 flex d-flex align-center">
        <v-icon class="mainRed--text">mdi-help-circle</v-icon> <span class="ml-1 mainRed--text text-body-1">view sample</span>
      </div>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Example Bill of Sale</span>
      </v-card-title>
      <v-divider class="mx-4"></v-divider>
      <v-card-text>
        <v-img src="@/assets/doc/bill_of_sale.jpg"></v-img>
      </v-card-text>
      <v-divider class="mx-4"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="bosExampleModal = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  created() {},
  props: {
    text: {
      type: String,
      default: 'NEW',
      required: false,
    },
  },
  data: () => ({
    bosExampleModal: false,
  }),
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style scoped></style>
